// libs
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import { logger } from 'redux-logger';
import localForage from 'localforage';

// constant
import { PERSIST_KEY } from 'src/constants/routers';

// reducer
import rootReducer from './redux/reducers';

// saga
import rootSaga from './redux/saga';

const persistConfig = {
  key: PERSIST_KEY,
  storage: localForage,
  whitelist: [
    'authReducer',
    'moviesReducer',
    'pricesReducer',
    'postalCodesReducer',
    'theatersReducer',
    'pollsReducer'
  ]
};

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, rootReducer);

// only run logger middleware with env development
const middleware = [sagaMiddleware];
if (process.env.REACT_APP_NODE_ENV === 'development') {
  middleware.push(logger);
}

function configureStore() {
  const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware, logger))
  );
  sagaMiddleware.run(rootSaga);

  return store;
}

export const store = configureStore();
export const persistor = persistStore(store);
