import {
  GET_LIST_COUNTRIES_REQUEST,
  GET_LIST_COUNTRIES_SUCCESS,
  GET_LIST_COUNTRIES_FAILURE,
  GET_COUNTRY_REQUEST,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAILURE,
  UPDATE_COUNTRY_REQUEST,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_FAILURE,
  CREATE_COUNTRY_REQUEST,
  CREATE_COUNTRY_SUCCESS,
  CREATE_COUNTRY_FAILURE
} from 'src/constants/countries';
import { Country, GetCountryRequestData, SuccessPayload, FailurePayload, UpdateCountryRequest, CreateCountryRequest } from 'src/types/countries';

// Action Types
export interface GetListCountriesRequestAction {
  type: typeof GET_LIST_COUNTRIES_REQUEST;
}

export interface GetListCountriesSuccessAction {
  type: typeof GET_LIST_COUNTRIES_SUCCESS;
  payload: SuccessPayload<Country[]>;
}

export interface GetListCountriesFailureAction {
  type: typeof GET_LIST_COUNTRIES_FAILURE;
  payload: FailurePayload;
}

export interface GetCountryRequestAction {
  type: typeof GET_COUNTRY_REQUEST;
  payload: GetCountryRequestData;
}

export interface GetCountrySuccessAction {
  type: typeof GET_COUNTRY_SUCCESS;
  payload: SuccessPayload<Country>;
}

export interface GetCountryFailureAction {
  type: typeof GET_COUNTRY_FAILURE;
  payload: FailurePayload;
}

export interface UpdateCountryRequestAction {
  type: typeof UPDATE_COUNTRY_REQUEST;
  payload: UpdateCountryRequest;
}

export interface UpdateCountrySuccessAction {
  type: typeof UPDATE_COUNTRY_SUCCESS;
  payload: SuccessPayload<Country>;
}

export interface UpdateCountryFailureAction {
  type: typeof UPDATE_COUNTRY_FAILURE;
  payload: FailurePayload;
}

// Create Country
export interface CreateCountryRequestAction {
  type: typeof CREATE_COUNTRY_REQUEST;
  payload: CreateCountryRequest;
}

export interface CreateCountrySuccessAction {
  type: typeof CREATE_COUNTRY_SUCCESS;
  payload: SuccessPayload<Country>;
}

export interface CreateCountryFailureAction {
  type: typeof CREATE_COUNTRY_FAILURE;
  payload: FailurePayload;
}

// Union type for all country actions
export type CountryActions =
  | GetListCountriesRequestAction
  | GetListCountriesSuccessAction
  | GetListCountriesFailureAction
  | GetCountryRequestAction
  | GetCountrySuccessAction
  | GetCountryFailureAction
  | UpdateCountryRequestAction
  | UpdateCountrySuccessAction
  | UpdateCountryFailureAction
  | CreateCountryRequestAction
  | CreateCountrySuccessAction
  | CreateCountryFailureAction;

// Get list of countries
export const getListCountriesRequest = (): GetListCountriesRequestAction => ({
  type: GET_LIST_COUNTRIES_REQUEST
});

export const getListCountriesSuccess = (data: Country[]): GetListCountriesSuccessAction => ({
  type: GET_LIST_COUNTRIES_SUCCESS,
  payload: data
});

export const getListCountriesFailure = (error: string): GetListCountriesFailureAction => ({
  type: GET_LIST_COUNTRIES_FAILURE,
  payload: error
});

// Get single country
export const getCountryRequest = (data: GetCountryRequestData): GetCountryRequestAction => ({
  type: GET_COUNTRY_REQUEST,
  payload: data
});

export const getCountrySuccess = (data: Country): GetCountrySuccessAction => ({
  type: GET_COUNTRY_SUCCESS,
  payload: data
});

export const getCountryFailure = (error: string): GetCountryFailureAction => ({
  type: GET_COUNTRY_FAILURE,
  payload: error
});

// Update country
export const updateCountryRequest = (data: UpdateCountryRequest): UpdateCountryRequestAction => ({
  type: UPDATE_COUNTRY_REQUEST,
  payload: data
});

export const updateCountrySuccess = (data: Country): UpdateCountrySuccessAction => ({
  type: UPDATE_COUNTRY_SUCCESS,
  payload: data
});

export const updateCountryFailure = (error: string): UpdateCountryFailureAction => ({
  type: UPDATE_COUNTRY_FAILURE,
  payload: error
});

// Create country
export const createCountryRequest = (data: CreateCountryRequest): CreateCountryRequestAction => ({
  type: CREATE_COUNTRY_REQUEST,
  payload: data
});

export const createCountrySuccess = (data: Country): CreateCountrySuccessAction => ({
  type: CREATE_COUNTRY_SUCCESS,
  payload: data
});

export const createCountryFailure = (error: string): CreateCountryFailureAction => ({
  type: CREATE_COUNTRY_FAILURE,
  payload: error
}); 