import React, { useState } from 'react';
import * as Icons from 'lucide-react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { NavigationGroup } from '@/constants/navigation';
import NavItem from './NavItem';

interface NavGroupProps {
  group: NavigationGroup;
}

const NavGroup: React.FC<NavGroupProps> = ({ group }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const renderIcon = () => {
    if (group.icon && Object.prototype.hasOwnProperty.call(Icons, group.icon)) {
      const DynamicIcon = (Icons as any)[group.icon];
      return <DynamicIcon className="mr-2 h-4 w-4" />;
    }
    return null;
  };

  return (
    <div className="space-y-1">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center w-full px-3 py-2 text-sm font-medium rounded-md hover:bg-accent hover:text-accent-foreground"
      >
        {renderIcon()}
        <span className="flex-1 text-left">{group.title}</span>
        {isExpanded ? (
          <ChevronDown className="h-4 w-4" />
        ) : (
          <ChevronRight className="h-4 w-4" />
        )}
      </button>
      {isExpanded && (
        <div className="ml-4 space-y-1">
          {group.items.map((item) => (
            <NavItem
              key={item.href}
              href={item.href}
              icon={item.icon}
              title={item.title}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default NavGroup; 