import {
  GET_LIST_BADGE_TYPES_REQUEST,
  GET_LIST_BADGE_TYPES_SUCCESS,
  GET_LIST_BADGE_TYPES_FAILURE,
  GET_BADGE_TYPE_REQUEST,
  GET_BADGE_TYPE_SUCCESS,
  GET_BADGE_TYPE_FAILURE,
  CREATE_BADGE_TYPE_REQUEST,
  CREATE_BADGE_TYPE_SUCCESS,
  CREATE_BADGE_TYPE_FAILURE,
  UPDATE_BADGE_TYPE_REQUEST,
  UPDATE_BADGE_TYPE_SUCCESS,
  UPDATE_BADGE_TYPE_FAILURE
} from 'src/constants/badgeTypes';
import { BadgeType, GetBadgeTypeRequestData, SuccessPayload, FailurePayload, CreateBadgeTypeRequest } from 'src/types/badgeTypes';

// Action Types
export interface GetListBadgeTypesRequestAction {
  type: typeof GET_LIST_BADGE_TYPES_REQUEST;
}

export interface GetListBadgeTypesSuccessAction {
  type: typeof GET_LIST_BADGE_TYPES_SUCCESS;
  payload: SuccessPayload<BadgeType[]>;
}

export interface GetListBadgeTypesFailureAction {
  type: typeof GET_LIST_BADGE_TYPES_FAILURE;
  payload: FailurePayload;
}

export interface GetBadgeTypeRequestAction {
  type: typeof GET_BADGE_TYPE_REQUEST;
  payload: GetBadgeTypeRequestData;
}

export interface GetBadgeTypeSuccessAction {
  type: typeof GET_BADGE_TYPE_SUCCESS;
  payload: SuccessPayload<BadgeType>;
}

export interface GetBadgeTypeFailureAction {
  type: typeof GET_BADGE_TYPE_FAILURE;
  payload: FailurePayload;
}

export interface CreateBadgeTypeRequestAction {
  type: typeof CREATE_BADGE_TYPE_REQUEST;
  payload: CreateBadgeTypeRequest;
}

export interface CreateBadgeTypeSuccessAction {
  type: typeof CREATE_BADGE_TYPE_SUCCESS;
  payload: SuccessPayload<BadgeType>;
}

export interface CreateBadgeTypeFailureAction {
  type: typeof CREATE_BADGE_TYPE_FAILURE;
  payload: FailurePayload;
}

export interface UpdateBadgeTypeRequestAction {
  type: typeof UPDATE_BADGE_TYPE_REQUEST;
  payload: BadgeType;
}

export interface UpdateBadgeTypeSuccessAction {
  type: typeof UPDATE_BADGE_TYPE_SUCCESS;
  payload: SuccessPayload<BadgeType>;
}

export interface UpdateBadgeTypeFailureAction {
  type: typeof UPDATE_BADGE_TYPE_FAILURE;
  payload: FailurePayload;
}

// Union type for all badge type actions
export type BadgeTypeActions =
  | GetListBadgeTypesRequestAction
  | GetListBadgeTypesSuccessAction
  | GetListBadgeTypesFailureAction
  | GetBadgeTypeRequestAction
  | GetBadgeTypeSuccessAction
  | GetBadgeTypeFailureAction
  | CreateBadgeTypeRequestAction
  | CreateBadgeTypeSuccessAction
  | CreateBadgeTypeFailureAction
  | UpdateBadgeTypeRequestAction
  | UpdateBadgeTypeSuccessAction
  | UpdateBadgeTypeFailureAction;

// Get list of badge types
export const getListBadgeTypesRequest = (): GetListBadgeTypesRequestAction => ({
  type: GET_LIST_BADGE_TYPES_REQUEST
});

export const getListBadgeTypesSuccess = (data: BadgeType[]): GetListBadgeTypesSuccessAction => ({
  type: GET_LIST_BADGE_TYPES_SUCCESS,
  payload: data
});

export const getListBadgeTypesFailure = (error: string): GetListBadgeTypesFailureAction => ({
  type: GET_LIST_BADGE_TYPES_FAILURE,
  payload: error
});

// Get single badge type
export const getBadgeTypeRequest = (data: GetBadgeTypeRequestData): GetBadgeTypeRequestAction => ({
  type: GET_BADGE_TYPE_REQUEST,
  payload: data
});

export const getBadgeTypeSuccess = (data: BadgeType): GetBadgeTypeSuccessAction => ({
  type: GET_BADGE_TYPE_SUCCESS,
  payload: data
});

export const getBadgeTypeFailure = (error: string): GetBadgeTypeFailureAction => ({
  type: GET_BADGE_TYPE_FAILURE,
  payload: error
});

// Create badge type
export const createBadgeTypeRequest = (data: CreateBadgeTypeRequest): CreateBadgeTypeRequestAction => ({
  type: CREATE_BADGE_TYPE_REQUEST,
  payload: data
});

export const createBadgeTypeSuccess = (data: BadgeType): CreateBadgeTypeSuccessAction => ({
  type: CREATE_BADGE_TYPE_SUCCESS,
  payload: data
});

export const createBadgeTypeFailure = (error: string): CreateBadgeTypeFailureAction => ({
  type: CREATE_BADGE_TYPE_FAILURE,
  payload: error
});

// Update badge type
export const updateBadgeTypeRequest = (data: BadgeType): UpdateBadgeTypeRequestAction => ({
  type: UPDATE_BADGE_TYPE_REQUEST,
  payload: data
});

export const updateBadgeTypeSuccess = (data: BadgeType): UpdateBadgeTypeSuccessAction => ({
  type: UPDATE_BADGE_TYPE_SUCCESS,
  payload: data
});

export const updateBadgeTypeFailure = (error: string): UpdateBadgeTypeFailureAction => ({
  type: UPDATE_BADGE_TYPE_FAILURE,
  payload: error
}); 