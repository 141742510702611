import React from 'react';

/**
 * Loading screen component to display while loading data
 */
const LoadingScreen = () => {
  return (
    <div 
      data-testid="loading-screen" 
      className="flex flex-col items-center justify-center h-full min-h-screen bg-gray-50 p-5"
    >
      <div className="w-16 h-16 border-4 border-blue-400 border-t-blue-600 rounded-full animate-spin"></div>
      <p className="mt-4 text-gray-700 text-lg">Loading...</p>
    </div>
  );
};

export default LoadingScreen;
