import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import routes, { renderRoutes } from './routes';
import GlobalStyles from './components/GlobalStyles';
import { Toaster } from './components/ui/sonner';

import './styles/globals.css';

const history = createBrowserHistory();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Toaster position="bottom-right" richColors={true} />
      <GlobalStyles />
      <Router history={history}>
        {renderRoutes(routes)}
      </Router>
    </ThemeProvider>
  );
};

export default App;
