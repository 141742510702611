import { put, call, takeLatest } from 'redux-saga/effects';

// reducers
import { Types, Creators } from 'src/redux/actions/pollsAction';

// apis
import { getPollByKey, createPoll } from 'src/api-client/pollsApi';

function* getPollRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(getPollByKey, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.getPollSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    // if poll not found, return empty array
    if (error && error.status === 404) {
      yield put(Creators.getPollSuccess([]));
      return;
    }
    yield put(Creators.getPollFailed(error || e));
  }
}

function* updatePollRequest(action) {
  const { params, callback } = action;

  try {
    const response = yield call(createPoll, params);
    if (!response || !response.data) {
      throw new Error('Request failed.');
    }

    if (callback) {
      callback(response.data, null);
    }

    yield put(Creators.updatePollSuccess(response.data));
  } catch (e) {
    const error = e.response && e.response.data;

    if (callback) {
      callback(null, error || e);
    }

    yield put(Creators.updatePollFailed(error || e));
  }
}

export default function* watchPollsRequest() {
  yield takeLatest(Types.GET_POLL_REQUEST, getPollRequest);
  yield takeLatest(Types.UPDATE_POLL_REQUEST, updatePollRequest);
}