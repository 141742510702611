import React from 'react';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { Separator } from '@/components/ui/separator';
import { Menu } from 'lucide-react';
import NavGroup from './NavGroup';
import { NAVIGATION_GROUPS } from '@/constants/navigation';

interface NavBarProps {
  onMobileOpen: () => void;
  onMobileClose: () => void;
  openMobile: boolean;
  onLogout: () => void;
  nameUser: string;
}

const NavBar: React.FC<NavBarProps> = ({ 
  onMobileOpen, 
  onMobileClose, 
  openMobile, 
  onLogout, 
  nameUser 
}) => {
  return (
    <>
      {/* Desktop Navigation */}
      <div className="hidden lg:flex flex-col w-64 fixed inset-y-0 left-0 z-20 border-r">
        <div className="p-4">
          <h2 className="text-2xl font-bold">MoviePass Admin</h2>
        </div>
        <Separator />
        <div className="flex-1 overflow-y-auto p-4">
          <nav className="space-y-4">
            {NAVIGATION_GROUPS.map((group) => (
              <NavGroup key={group.title} group={group} />
            ))}
          </nav>
        </div>
        <Separator />
        <div className="p-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="font-medium">{nameUser}</p>
            </div>
            <Button variant="outline" onClick={onLogout}>
              Logout
            </Button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      <div className="lg:hidden fixed top-0 left-0 right-0 z-20 bg-background border-b p-4 flex items-center justify-between">
        <h2 className="text-xl font-bold">MoviePass Admin</h2>
        <Sheet 
          open={openMobile} 
          onOpenChange={(open: boolean) => open ? onMobileOpen() : onMobileClose()}
        >
          <SheetTrigger asChild>
            <Button variant="outline" size="icon" onClick={onMobileOpen}>
              <Menu className="h-5 w-5" />
            </Button>
          </SheetTrigger>
          <SheetContent side="left" className="w-64 p-0">
            <div className="p-4">
              <h2 className="text-2xl font-bold">MoviePass Admin</h2>
            </div>
            <Separator />
            <div className="flex-1 overflow-y-auto p-4">
              <nav className="space-y-4">
                {NAVIGATION_GROUPS.map((group) => (
                  <NavGroup key={group.title} group={group} />
                ))}
              </nav>
            </div>
            <Separator />
            <div className="p-4">
              <div className="flex items-center justify-between">
                <div>
                  <p className="font-medium">{nameUser}</p>
                </div>
                <Button variant="outline" onClick={onLogout}>
                  Logout
                </Button>
              </div>
            </div>
          </SheetContent>
        </Sheet>
      </div>
    </>
  );
};

export default NavBar; 