import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import {
  GET_LIST_BADGE_TYPES_REQUEST,
  GET_BADGE_TYPE_REQUEST,
  CREATE_BADGE_TYPE_REQUEST,
  UPDATE_BADGE_TYPE_REQUEST
} from 'src/constants/badgeTypes';
import {
  getListBadgeTypesSuccess,
  getListBadgeTypesFailure,
  getBadgeTypeSuccess,
  getBadgeTypeFailure,
  createBadgeTypeSuccess,
  createBadgeTypeFailure,
  updateBadgeTypeSuccess,
  updateBadgeTypeFailure,
  GetBadgeTypeRequestAction,
  CreateBadgeTypeRequestAction,
  UpdateBadgeTypeRequestAction
} from 'src/redux/actions/badgeTypesAction';
import {
  getListBadgeTypes,
  getBadgeType,
  createBadgeType,
  updateBadgeType
} from 'src/api-client/badgeTypesApi';
import { BadgeType, BadgeTypeResponse } from 'src/types/badgeTypes';

/**
 * Saga worker for getting list of badge types
 */
function* getListBadgeTypesSaga() {
  try {
    const response: AxiosResponse<BadgeTypeResponse> = yield call(getListBadgeTypes);
    yield put(getListBadgeTypesSuccess(response.data.badges));
  } catch (error: any) {
    // Extract the detailed error message from the axios error response
    let errorMessage = error.response.data.errors[0].message || 'Failed to retrieve badge types';

    console.log('error', error.response.data.errors[0].message);
    console.log('errorMessage', errorMessage);
    
    yield put(getListBadgeTypesFailure(errorMessage));
  }
}

/**
 * Saga worker for getting a single badge type
 * @param {GetBadgeTypeRequestAction} action - Action object
 */
function* getBadgeTypeSaga(action: GetBadgeTypeRequestAction) {
  try {
    const response: AxiosResponse<BadgeType> = yield call(getBadgeType, action.payload);
    yield put(getBadgeTypeSuccess(response.data));
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Failed to retrieve badge type';
    yield put(getBadgeTypeFailure(errorMessage));
  }
}

/**
 * Saga worker for creating a badge type
 * @param {CreateBadgeTypeRequestAction} action - Action object
 */
function* createBadgeTypeSaga(action: CreateBadgeTypeRequestAction) {
  try {
    const response: AxiosResponse<BadgeType> = yield call(createBadgeType, action.payload);
    yield put(createBadgeTypeSuccess(response.data));
  } catch (error: any) {
    let errorMessage = 'Failed to create badge type';
    
    // Extract detailed error message from API response if available
    if (error.response && error.response.data) {
      if (error.response.data.errors && error.response.data.errors.length > 0) {
        // Get the detailed error message from the errors array
        const errorDetail = error.response.data.errors[0].message;
        // Try to extract more specific error message if in the format "Error: 400 - {"error":"specific message"}"
        const nestedErrorMatch = errorDetail.match(/Error: \d+ - (.*)/);
        if (nestedErrorMatch && nestedErrorMatch[1]) {
          try {
            const nestedError = JSON.parse(nestedErrorMatch[1]);
            if (nestedError.error) {
              errorMessage = nestedError.error;
            } else {
              errorMessage = errorDetail;
            }
          } catch (e) {
            errorMessage = errorDetail;
          }
        } else {
          errorMessage = errorDetail;
        }
      } else if (error.response.data.message) {
        errorMessage = error.response.data.message;
      } else if (typeof error.response.data === 'string') {
        errorMessage = error.response.data;
      }
    } else if (error.message) {
      errorMessage = error.message;
    }
    
    // For debugging
    console.log('Create badge type error:', error.response?.data);
    
    yield put(createBadgeTypeFailure(JSON.stringify(error.response?.data || { message: errorMessage })));
  }
}

/**
 * Saga worker for updating a badge type
 * @param {UpdateBadgeTypeRequestAction} action - Action object
 */
function* updateBadgeTypeSaga(action: UpdateBadgeTypeRequestAction) {
  try {
    const response: AxiosResponse<BadgeType> = yield call(updateBadgeType, action.payload);
    yield put(updateBadgeTypeSuccess(response.data));
  } catch (error: any) {
    let errorMessage = 'Failed to update badge type';
    
    // Extract detailed error message from API response if available
    if (error.response && error.response.data) {
      if (error.response.data.errors && error.response.data.errors.length > 0) {
        // Get the detailed error message from the errors array
        const errorDetail = error.response.data.errors[0].message;
        // Try to extract more specific error message if in the format "Error: 400 - {"error":"specific message"}"
        const nestedErrorMatch = errorDetail.match(/Error: \d+ - (.*)/);
        if (nestedErrorMatch && nestedErrorMatch[1]) {
          try {
            const nestedError = JSON.parse(nestedErrorMatch[1]);
            if (nestedError.error) {
              errorMessage = nestedError.error;
            } else {
              errorMessage = errorDetail;
            }
          } catch (e) {
            errorMessage = errorDetail;
          }
        } else {
          errorMessage = errorDetail;
        }
      } else if (error.response.data.message) {
        errorMessage = error.response.data.message;
      } else if (typeof error.response.data === 'string') {
        errorMessage = error.response.data;
      }
    } else if (error.message) {
      errorMessage = error.message;
    }
    
    // For debugging
    console.log('Update badge type error:', error.response?.data);
    
    yield put(updateBadgeTypeFailure(JSON.stringify(error.response?.data || { message: errorMessage })));
  }
}

/**
 * Badge types saga watcher
 */
export default function* badgeTypesSaga() {
  yield takeLatest(GET_LIST_BADGE_TYPES_REQUEST, getListBadgeTypesSaga);
  yield takeLatest(GET_BADGE_TYPE_REQUEST, getBadgeTypeSaga);
  yield takeLatest(CREATE_BADGE_TYPE_REQUEST, createBadgeTypeSaga);
  yield takeLatest(UPDATE_BADGE_TYPE_REQUEST, updateBadgeTypeSaga);
} 