import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse, AxiosError } from 'axios';
import {
  GET_LIST_COUNTRIES_REQUEST,
  GET_COUNTRY_REQUEST,
  UPDATE_COUNTRY_REQUEST,
  CREATE_COUNTRY_REQUEST,
  RETRIEVE_COUNTRIES_FAILED,
  UPDATE_COUNTRY_FAILED,
  CREATE_COUNTRY_FAILED
} from 'src/constants/countries';
import { Country } from 'src/types/countries';
import {
  getListCountriesSuccess,
  getListCountriesFailure,
  GetCountryRequestAction,
  getCountrySuccess,
  getCountryFailure,
  UpdateCountryRequestAction,
  updateCountrySuccess,
  updateCountryFailure,
  CreateCountryRequestAction,
  createCountrySuccess,
  createCountryFailure
} from '../actions/countriesAction';
import {
  getListCountries,
  getCountry,
  updateCountry,
  createCountry
} from 'src/api-client/countriesApi';

/**
 * Saga worker for fetching list of countries
 */
export function* fetchCountriesWorker() {
  try {
    const response: AxiosResponse<Country[]> = yield call(getListCountries);
    
    if (response && response.data) {
      yield put(getListCountriesSuccess(response.data));
    } else {
      yield put(getListCountriesFailure(RETRIEVE_COUNTRIES_FAILED));
    }
  } catch (error: unknown) {
    const axiosError = error as AxiosError;
    yield put(getListCountriesFailure(axiosError.message || RETRIEVE_COUNTRIES_FAILED));
  }
}

/**
 * Saga worker for fetching a single country
 * @param {GetCountryRequestAction} action - Action with country ID payload
 */
export function* fetchCountryWorker(action: GetCountryRequestAction) {
  try {
    const response: AxiosResponse<Country> = yield call(getCountry, action.payload);
    
    if (response && response.data) {
      yield put(getCountrySuccess(response.data));
    } else {
      yield put(getCountryFailure('Failed to retrieve country details'));
    }
  } catch (error: unknown) {
    const axiosError = error as AxiosError;
    yield put(getCountryFailure(axiosError.message || 'Failed to retrieve country details'));
  }
}

/**
 * Saga worker for updating a country
 * @param {UpdateCountryRequestAction} action - Action with country data payload
 */
export function* updateCountryWorker(action: UpdateCountryRequestAction) {
  try {
    const response: AxiosResponse<Country> = yield call(updateCountry, action.payload);
    
    if (response && response.data) {
      yield put(updateCountrySuccess(response.data));
    } else {
      yield put(updateCountryFailure(UPDATE_COUNTRY_FAILED));
    }
  } catch (error: unknown) {
    const axiosError = error as AxiosError;
    yield put(updateCountryFailure(axiosError.message || UPDATE_COUNTRY_FAILED));
  }
}

/**
 * Saga worker for creating a new country
 */
export function* createCountryWorker(action: CreateCountryRequestAction) {
  try {
    const response: AxiosResponse<Country> = yield call(createCountry, action.payload);
    
    if (response && response.data) {
      yield put(createCountrySuccess(response.data));
    } else {
      yield put(createCountryFailure(CREATE_COUNTRY_FAILED));
    }
  } catch (error: unknown) {
    const axiosError = error as AxiosError;
    yield put(createCountryFailure(axiosError.message || CREATE_COUNTRY_FAILED));
  }
}

/**
 * Saga watcher for countries
 */
export function* watchCountriesRequest() {
  yield takeLatest(GET_LIST_COUNTRIES_REQUEST, fetchCountriesWorker);
  yield takeLatest(GET_COUNTRY_REQUEST, fetchCountryWorker);
  yield takeLatest(UPDATE_COUNTRY_REQUEST, updateCountryWorker);
  yield takeLatest(CREATE_COUNTRY_REQUEST, createCountryWorker);
}

export default watchCountriesRequest; 