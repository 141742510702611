// Action Types
export const GET_LIST_BADGE_TYPES_REQUEST = 'GET_LIST_BADGE_TYPES_REQUEST';
export const GET_LIST_BADGE_TYPES_SUCCESS = 'GET_LIST_BADGE_TYPES_SUCCESS';
export const GET_LIST_BADGE_TYPES_FAILURE = 'GET_LIST_BADGE_TYPES_FAILURE';

export const GET_BADGE_TYPE_REQUEST = 'GET_BADGE_TYPE_REQUEST';
export const GET_BADGE_TYPE_SUCCESS = 'GET_BADGE_TYPE_SUCCESS';
export const GET_BADGE_TYPE_FAILURE = 'GET_BADGE_TYPE_FAILURE';

export const CREATE_BADGE_TYPE_REQUEST = 'CREATE_BADGE_TYPE_REQUEST';
export const CREATE_BADGE_TYPE_SUCCESS = 'CREATE_BADGE_TYPE_SUCCESS';
export const CREATE_BADGE_TYPE_FAILURE = 'CREATE_BADGE_TYPE_FAILURE';

export const UPDATE_BADGE_TYPE_REQUEST = 'UPDATE_BADGE_TYPE_REQUEST';
export const UPDATE_BADGE_TYPE_SUCCESS = 'UPDATE_BADGE_TYPE_SUCCESS';
export const UPDATE_BADGE_TYPE_FAILURE = 'UPDATE_BADGE_TYPE_FAILURE';

// Message Constants
export const RETRIEVE_BADGE_TYPES_SUCCESS = 'Badge Types retrieved successfully';
export const RETRIEVE_BADGE_TYPES_FAILED = 'Failed to retrieve Badge Types';

/**
 * Table column interface
 */
export interface TableColumn {
  id: string;
  label: string;
  align: 'left' | 'right' | 'center';
}

// Table Columns
export const BADGE_TYPES_TABLE: TableColumn[] = [
  {
    id: 'id',
    label: 'ID',
    align: 'left'
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left'
  },
  {
    id: 'imageUrl',
    label: 'Image URL',
    align: 'left'
  },
  {
    id: 'thumbnailUrl',
    label: 'Thumbnail URL',
    align: 'left'
  },
  {
    id: 'actions',
    label: 'Actions',
    align: 'left'
  }
]; 