import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

/**
 * Handle request server to get list of user
 * @param {*} param
 * @returns response
 */
export const getListAdminUsers = async ({ key = undefined, status = undefined, offset = 0, limit = 10 }) => {
  const response = await adminAPI.get(ENDPOINT.ADMIN_USERS, {
    params: { key, status, offset, limit },
    headers: authHeader()
  });

  return response;
};

/**
 * Handle request server to get list of user
 * @param {*} param
 * @returns response
 */
export const getAdminUser = async ({ id }) => {
  const response = await adminAPI.get(`${ENDPOINT.ADMIN_USERS}/${id}`, {
    headers: authHeader()
  });

  return response;
};

/**
 * Handle request server to create new user
 * @param {*} param0
 * @returns
 */
export const createAdminUser = async ({
  email,
  firstName,
  lastName,
  roleValue,
  password
}) => {
  const response = await adminAPI.post(
    ENDPOINT.ADMIN_USERS,
    {
      email,
      firstName,
      lastName,
      role: roleValue,
      password
    },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Handle request server update profile for user
 * @param {*} param0
 * @returns
 */
export const updateProfileAdminUser = async ({
  userID,
  firstName,
  lastName,
  email,
  roleValue,
}) => {
  const response = await adminAPI.put(
    `${ENDPOINT.ADMIN_USERS}/${userID}`,
    {
      firstName,
      lastName,
      email,
      role: roleValue,
    },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Handle change password request
 * @param {*} param0
 * @returns
 */
export const changeAdminPassword = async ({ adminUserId, password }) => {
  const response = await adminAPI.post(
    `${ENDPOINT.ADMIN_USERS}/${adminUserId}${ENDPOINT.CHANGE_PASSWORD}`,
    { password },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Handle reset password request
 * @param {*} param0
 * @returns
 */
export const resetAdminPassword = async ({ email }) => {
  const response = await adminAPI.post(
    ENDPOINT.RESET_ADMIN_PASSWORD,
    { email },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Handle cancel user request
 * @param {*} param0
 * @returns
 */
export const cancelAdminUser = async ({ reason, userId, adminId, endDate }) => {
  const response = await adminAPI.post(
    ENDPOINT.CANCEL_ADMIN_USER,
    {
      reason,
      userId,
      adminId,
      action: 'CANCEL_USER',
      payload: { endDate }
    },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Handle reset password request
 * @param {*} param0
 * @returns
 */
export const activateAdminUser = async ({ adminUserId }) => {
  const response = await adminAPI.post(
    `${ENDPOINT.ADMIN_USERS}/${adminUserId}${ENDPOINT.ACTIVATE}`,
    { status: 'ACTIVE' },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Handle reset password request
 * @param {*} param0
 * @returns
 */
export const deactivateAdminUser = async ({ adminUserId }) => {
  const response = await adminAPI.post(
    `${ENDPOINT.ADMIN_USERS}/${adminUserId}${ENDPOINT.DEACTIVATE}`,
    { status: 'INACTIVE' },
    { headers: authHeader() }
  );

  return response;
};
