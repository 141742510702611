import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';
import { BadgeType, BadgeTypeResponse, CreateBadgeTypeRequest, GetBadgeTypeRequestData } from 'src/types/badgeTypes';
import { AxiosResponse } from 'axios';

// Define badge type endpoint path
const BADGE_TYPE_ENDPOINT = '/badge_type';

/**
 * Get list of badge types from the API
 * @returns {Promise<AxiosResponse<BadgeTypeResponse>>} - API response with badge types
 */
export const getListBadgeTypes = async (): Promise<AxiosResponse<BadgeTypeResponse>> => {
  const response = await adminAPI.get(`${BADGE_TYPE_ENDPOINT}`, {
    headers: authHeader()
  });

  return response;
};

/**
 * Get a single badge type by ID
 * @param {GetBadgeTypeRequestData} data - Request data
 * @returns {Promise<AxiosResponse<BadgeType>>} - API response with badge type
 */
export const getBadgeType = async (data: GetBadgeTypeRequestData): Promise<AxiosResponse<BadgeType>> => {
  const { id } = data;
  const response = await adminAPI.get(`${BADGE_TYPE_ENDPOINT}/${id}`, {
    headers: authHeader()
  });

  return response;
};

/**
 * Create a new badge type
 * @param {BadgeType} data - Badge type data
 * @returns {Promise<AxiosResponse<BadgeType>>} - API response with created badge type
 */
export const createBadgeType = async (data: CreateBadgeTypeRequest): Promise<AxiosResponse<BadgeType>> => {
  const { name, imageUrl, thumbnailUrl } = data;
  
  const response = await adminAPI.post(
    `${BADGE_TYPE_ENDPOINT}`,
    {
      badgeName: name,
      imageUrl, 
      thumbnailUrl
    },
    { headers: authHeader() }
  );

  return response;
};

/**
 * Update a badge type
 * @param {BadgeType} data - Badge type data with ID
 * @returns {Promise<AxiosResponse<BadgeType>>} - API response with updated badge type
 */
export const updateBadgeType = async (data: BadgeType): Promise<AxiosResponse<BadgeType>> => {
  const { id, ...badgeTypeData } = data;
  
  const response = await adminAPI.put(
    `${BADGE_TYPE_ENDPOINT}/${id}`,
    badgeTypeData,
    { headers: authHeader() }
  );

  return response;
}; 