import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as Icons from 'lucide-react';
import { CircleDot } from 'lucide-react';

interface NavItemProps {
  href: string;
  icon?: string;
  title: string;
}

const NavItem: React.FC<NavItemProps> = ({ href, icon, title }) => {
  const location = useLocation();
  const isActive = location.pathname === href || location.pathname.startsWith(`${href}/`);
  
  // Get dynamic icon or fall back to CircleDot
  const renderIcon = () => {
    // If we have an icon name and it exists in the Icons object
    if (icon && Object.prototype.hasOwnProperty.call(Icons, icon)) {
      const DynamicIcon = (Icons as any)[icon];
      return <DynamicIcon className="mr-2 h-4 w-4" />;
    }
    
    // Fallback icon
    return <CircleDot className="mr-2 h-4 w-4" />;
  };
  
  return (
    <Link
      to={href}
      className={`flex items-center px-3 py-2 rounded-md transition-colors ${
        isActive 
          ? 'bg-primary text-primary-foreground' 
          : 'hover:bg-accent hover:text-accent-foreground'
      }`}
    >
      {renderIcon()}
      <span>{title}</span>
    </Link>
  );
};

NavItem.defaultProps = {
  icon: 'CircleDot'
};

export default NavItem; 