import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NavBar from './NavBar';

import '../../styles/globals.css';

const DashboardLayout = ({ children, logoutRequest, name }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  // Handler for opening mobile navigation
  const handleMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  // Handler for closing mobile navigation
  const handleMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  return (
    <div className="flex h-full w-full overflow-hidden bg-background">
      <NavBar
        onMobileOpen={handleMobileNavOpen}
        onMobileClose={handleMobileNavClose}
        openMobile={isMobileNavOpen}
        onLogout={logoutRequest}
        nameUser={name}
      />
      <div className="flex w-full lg:pl-64 bg-background">
        <div className="flex flex-1 overflow-hidden pl-10">
          <div className="flex-1 h-full overflow-auto">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
  logoutRequest: PropTypes.func,
  name: PropTypes.string
};

DashboardLayout.defaultProps = {
  children: {},
  logoutRequest: () => {},
  name: ''
};

export default DashboardLayout;
