import { adminAPI, authHeader } from './config';
import { Country, CountryResponse, GetCountryRequestData, UpdateCountryRequest, CreateCountryRequest } from 'src/types/countries';
import { AxiosResponse } from 'axios';

// Define country endpoint path - remove the duplicated prefix
const COUNTRY_ENDPOINT = '/countries';

/**
 * Get list of countries from the API
 * @returns {Promise<AxiosResponse<CountryResponse>>} - API response with countries
 */
export const getListCountries = async (): Promise<AxiosResponse<Country[]>> => {
  const response = await adminAPI.get(`${COUNTRY_ENDPOINT}`, {
    headers: authHeader()
  });

  return response;
};

/**
 * Get a single country by ID
 * @param {GetCountryRequestData} data - Request data
 * @returns {Promise<AxiosResponse<Country>>} - API response with country
 */
export const getCountry = async (data: GetCountryRequestData): Promise<AxiosResponse<Country>> => {
  const { id } = data;
  const response = await adminAPI.get(`${COUNTRY_ENDPOINT}/${id}`, {
    headers: authHeader()
  });

  return response;
};

/**
 * Update a country
 * @param {UpdateCountryRequest} data - Country data with ID
 * @returns {Promise<AxiosResponse<Country>>} - API response with updated country
 */
export const updateCountry = async (data: UpdateCountryRequest): Promise<AxiosResponse<Country>> => {
  const { id, ...countryData } = data;
  
  const response = await adminAPI.put(
    `${COUNTRY_ENDPOINT}/${id}`,
    countryData,
    { headers: authHeader() }
  );

  return response;
};

/**
 * Create a new country
 * @param {CreateCountryRequest} data - Country data
 * @returns {Promise<AxiosResponse<Country>>} - API response with created country
 */
export const createCountry = async (data: CreateCountryRequest): Promise<AxiosResponse<Country>> => {
  const response = await adminAPI.post(
    COUNTRY_ENDPOINT,
    data,
    { headers: authHeader() }
  );

  return response;
}; 