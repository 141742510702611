import {
  GET_LIST_BADGE_TYPES_REQUEST,
  GET_LIST_BADGE_TYPES_SUCCESS,
  GET_LIST_BADGE_TYPES_FAILURE,
  GET_BADGE_TYPE_REQUEST,
  GET_BADGE_TYPE_SUCCESS,
  GET_BADGE_TYPE_FAILURE,
  CREATE_BADGE_TYPE_REQUEST,
  CREATE_BADGE_TYPE_SUCCESS,
  CREATE_BADGE_TYPE_FAILURE,
  UPDATE_BADGE_TYPE_REQUEST,
  UPDATE_BADGE_TYPE_SUCCESS,
  UPDATE_BADGE_TYPE_FAILURE
} from 'src/constants/badgeTypes';
import { BadgeTypesState } from 'src/types/badgeTypes';
import { BadgeTypeActions } from '@/redux/actions/badgeTypesAction';

const initialState: BadgeTypesState = {
  badgeTypes: [],
  badgeType: {
    packageId: '',
    badgeNftPackageId: '',
    badgeTypeName: '',
    badgeTypeDescription: '',
    badgeTypePublisher: '',
    displayObjectId: '',
    displayImageUrl: '',
    displayThumbnailUrl: '',
    createdAt: ''
  },
  isLoading: false,
  error: ''
};

const badgeTypesReducer = (state = initialState, action: BadgeTypeActions): BadgeTypesState => {
  switch (action.type) {
    // Get list of badge types
    case GET_LIST_BADGE_TYPES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ''
      };
    case GET_LIST_BADGE_TYPES_SUCCESS:
      return {
        ...state,
        badgeTypes: action.payload || [],
        isLoading: false,
        error: ''
      };
    case GET_LIST_BADGE_TYPES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    // Get single badge type
    case GET_BADGE_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ''
      };
    case GET_BADGE_TYPE_SUCCESS:
      return {
        ...state,
        badgeType: action.payload,
        isLoading: false,
        error: ''
      };
    case GET_BADGE_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    // Create badge type
    case CREATE_BADGE_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ''
      };
    case CREATE_BADGE_TYPE_SUCCESS:
      return {
        ...state,
        badgeType: action.payload,
        isLoading: false,
        error: ''
      };
    case CREATE_BADGE_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    // Update badge type
    case UPDATE_BADGE_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ''
      };
    case UPDATE_BADGE_TYPE_SUCCESS:
      return {
        ...state,
        badgeType: action.payload,
        isLoading: false,
        error: ''
      };
    case UPDATE_BADGE_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default badgeTypesReducer; 