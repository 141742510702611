import ENDPOINT from './ApiEndpoint';
import { adminAPI, authHeader } from './config';

export const getCardTransactions = async ({ id, fromTimestamp, toTimestamp, ...otherParams }) => {
  // Log the input parameters for debugging
  console.log("getCardTransactions API - input params:", { 
    id, 
    idType: typeof id, 
    fromTimestamp, 
    toTimestamp,
    otherParams
  });
  
  if (!id) {
    throw new Error('Card ID is required');
  }
  
  // Ensure id is a string
  const cardId = String(id);
  
  const queryParams = new URLSearchParams();
  
  // Add timestamp filters if provided
  if (fromTimestamp !== null && fromTimestamp !== undefined) {
    queryParams.append('fromTimestamp', fromTimestamp.toString());
  }
  
  if (toTimestamp !== null && toTimestamp !== undefined) {
    queryParams.append('toTimestamp', toTimestamp.toString());
  }
  
  // Add any other params for future extensibility
  Object.entries(otherParams).forEach(([key, value]) => {
    // Skip internal parameters that start with underscore
    if (!key.startsWith('_') && value !== undefined && value !== null) {
      queryParams.append(key, value.toString());
    }
  });
  
  // Build URL with query parameters if any exist
  const queryString = queryParams.toString();
  const url = `${ENDPOINT.CARDS}/${cardId}${ENDPOINT.CARD_TRANSACTIONS}${queryString ? `?${queryString}` : ''}`;
  
  console.log("API request URL:", url);
  
  const response = await adminAPI.get(url, {
    headers: authHeader()
  });

  return response;
};

export const getCard = async ({ id }) => {
  const response = await adminAPI.get(`${ENDPOINT.CARDS}/${id}`, {
    headers: authHeader()
  });

  return response;
};

export const searchCardByProviderId = async ({ providerId }) => {
  const response = await adminAPI.get(`${ENDPOINT.CARDS}`, {
    params: { providerId },
    headers: authHeader()
  });

  return response;
};

export const changeCardStatus = async ({ id, statusValue, comment }) => {
  const response = await adminAPI.post(`${ENDPOINT.CARDS}/${id}${ENDPOINT.CHANGE_STATUS}`, {
    status: statusValue,
    comment
  }, {
    headers: authHeader()
  });

  return response;
};

export const reassociateCard = async ({ id, userId }) => {
  const response = await adminAPI.post(`${ENDPOINT.CARDS}/${id}${ENDPOINT.REASSOCIATE}`, {
    userId
  }, {
    headers: authHeader()
  });

  return response;
};
