import { LucideIcon } from 'lucide-react';

export interface NavigationItem {
  title: string;
  href: string;
  icon: string; // Name of Lucide icon
}

export interface NavigationGroup {
  title: string;
  icon: string; // Name of Lucide icon
  items: NavigationItem[];
}

export const NAVIGATION_GROUPS: NavigationGroup[] = [
  {
    title: 'User Management',
    icon: 'Users',
    items: [
      {
        title: 'Users',
        href: '/users',
        icon: 'Users'
      },
      {
        title: 'Cards',
        href: '/cards',
        icon: 'CreditCard'
      },
      {
        title: 'Gift Purchases',
        href: '/gift_purchases',
        icon: 'Gift'
      },
      {
        title: 'VIP Users',
        href: '/vip_users',
        icon: 'Crown'
      }
    ]
  },
  {
    title: 'Movie Management',
    icon: 'Film',
    items: [
      {
        title: 'Movies',
        href: '/movies',
        icon: 'Film'
      }
    ]
  },
  {
    title: 'Theater Management',
    icon: 'Building',
    items: [
      {
        title: 'Theaters',
        href: '/theaters',
        icon: 'Building'
      },
      {
        title: 'Theater Chains',
        href: '/theater_chains',
        icon: 'Building'
      },
      {
        title: 'Qualifiers',
        href: '/qualifiers',
        icon: 'Check'
      },
      {
        title: 'Ticket Types',
        href: '/ticket_types',
        icon: 'Ticket'
      }
    ]
  },
  {
    title: 'Payment & Billing',
    icon: 'CreditCard',
    items: [
      {
        title: 'Pricing',
        href: '/prices',
        icon: 'DollarSign'
      }
    ]
  },
  {
    title: 'Credit Management',
    icon: 'Calendar',
    items: [
      {
        title: 'Credit Schedules',
        href: '/credit_bands',
        icon: 'Calendar'
      },
      {
        title: 'Credit Offsets',
        href: '/credit_offsets',
        icon: 'Calendar'
      },
      {
        title: 'Service Fee Offsets',
        href: '/service_fee_offsets',
        icon: 'Calendar'
      }
    ]
  },
  {
    title: 'Marketing & Campaigns',
    icon: 'Megaphone',
    items: [
      {
        title: 'Advertising',
        href: '/advertisings',
        icon: 'Eye'
      },
      {
        title: 'Campaigns',
        href: '/campaigns',
        icon: 'Megaphone'
      },
      {
        title: 'Polls',
        href: '/polls',
        icon: 'BarChart'
      }
    ]
  },
  {
    title: 'Geography',
    icon: 'MapPin',
    items: [
      {
        title: 'Postal Codes',
        href: '/postal_codes',
        icon: 'MapPin'
      },
      {
        title: 'Countries',
        href: '/countries',
        icon: 'Globe'
      },
      {
        title: 'Networks',
        href: '/networks',
        icon: 'Network'
      }
    ]
  },
  {
    title: 'USIO',
    icon: 'Settings',
    items: [
      {
        title: 'Blacklists',
        href: '/blacklists',
        icon: 'Shield'
      }
    ]
  },
  {
    title: 'Partnerships',
    icon: 'PartyPopper',
    items: [
      {
        title: 'Corporate Partners',
        href: '/corporate_partners',
        icon: 'PartyPopper'
      }
    ]
  },
  {
    title: 'Web3',
    icon: 'Link',
    items: [
      {
        title: 'Badge Types',
        href: '/badge_types',
        icon: 'Badge'
      },
      {
        title: 'Bulk Mint Badges',
        href: '/web3/bulk-mint-badges',
        icon: 'BadgePlus'
      }
    ]
  },
  {
    title: 'Administrators',
    icon: 'Shield',
    items: [
      {
        title: 'Administrators',
        href: '/administrators',
        icon: 'Shield'
      }
    ]
  }
]; 