import {
  GET_LIST_COUNTRIES_REQUEST,
  GET_LIST_COUNTRIES_SUCCESS,
  GET_LIST_COUNTRIES_FAILURE,
  GET_COUNTRY_REQUEST,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAILURE,
  UPDATE_COUNTRY_REQUEST,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_FAILURE,
  CREATE_COUNTRY_REQUEST,
  CREATE_COUNTRY_SUCCESS,
  CREATE_COUNTRY_FAILURE
} from 'src/constants/countries';
import { CountriesState } from 'src/types/countries';
import { CountryActions } from '../actions/countriesAction';

// Initial state for countries
const initialState: CountriesState = {
  countries: [],
  country: null,
  isLoading: false,
  error: '',
};

/**
 * Reducer for countries-related actions
 * @param {CountriesState} state - Current state
 * @param {CountryActions} action - Dispatched action
 * @returns {CountriesState} - New state
 */
const countriesReducer = (
  state: CountriesState = initialState,
  action: CountryActions
): CountriesState => {
  switch (action.type) {
    // List Countries
    case GET_LIST_COUNTRIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case GET_LIST_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countries: action.payload,
        error: '',
      };
    case GET_LIST_COUNTRIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    // Single Country
    case GET_COUNTRY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        country: action.payload,
        error: '',
      };
    case GET_COUNTRY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      
    // Update Country
    case UPDATE_COUNTRY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case UPDATE_COUNTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        country: action.payload,
        // Also update the country in the countries array if it exists
        countries: state.countries.map(country => 
          country.id === action.payload.id ? action.payload : country
        ),
        error: '',
      };
    case UPDATE_COUNTRY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    // Create Country
    case CREATE_COUNTRY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case CREATE_COUNTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countries: [...state.countries, action.payload],
        error: '',
      };
    case CREATE_COUNTRY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default countriesReducer; 