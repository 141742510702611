// Action Types
export const GET_LIST_COUNTRIES_REQUEST = 'GET_LIST_COUNTRIES_REQUEST';
export const GET_LIST_COUNTRIES_SUCCESS = 'GET_LIST_COUNTRIES_SUCCESS';
export const GET_LIST_COUNTRIES_FAILURE = 'GET_LIST_COUNTRIES_FAILURE';

export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAILURE = 'GET_COUNTRY_FAILURE';

export const UPDATE_COUNTRY_REQUEST = 'UPDATE_COUNTRY_REQUEST';
export const UPDATE_COUNTRY_SUCCESS = 'UPDATE_COUNTRY_SUCCESS';
export const UPDATE_COUNTRY_FAILURE = 'UPDATE_COUNTRY_FAILURE';

export const CREATE_COUNTRY_REQUEST = 'CREATE_COUNTRY_REQUEST';
export const CREATE_COUNTRY_SUCCESS = 'CREATE_COUNTRY_SUCCESS';
export const CREATE_COUNTRY_FAILURE = 'CREATE_COUNTRY_FAILURE';

// Message Constants
export const RETRIEVE_COUNTRIES_SUCCESS = 'Countries retrieved successfully';
export const RETRIEVE_COUNTRIES_FAILED = 'Failed to retrieve Countries';
export const UPDATE_COUNTRY_SUCCESS_MESSAGE = 'Country updated successfully';
export const UPDATE_COUNTRY_FAILED = 'Failed to update country';
export const CREATE_COUNTRY_SUCCESS_MESSAGE = 'Country created successfully';
export const CREATE_COUNTRY_FAILED = 'Failed to create country';

/**
 * Table column interface
 */
export interface TableColumn {
  id: string;
  label: string;
  align: 'left' | 'right' | 'center';
}

// Table Columns
export const COUNTRIES_TABLE: TableColumn[] = [
  {
    id: 'id',
    label: 'ID',
    align: 'left'
  },
  {
    id: 'code',
    label: 'Code',
    align: 'left'
  },
  {
    id: 'name',
    label: 'Name',
    align: 'left'
  },
  {
    id: 'alpha_3',
    label: 'Alpha-3',
    align: 'left'
  },
  {
    id: 'numeric',
    label: 'Numeric',
    align: 'left'
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left'
  },
  {
    id: 'actions',
    label: 'Actions',
    align: 'left'
  }
]; 